import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import Img from "gatsby-image"
import { Card } from 'react-bootstrap';

import Layout from '../components/Layout/Layout';
import SimpleSlideShow from '../components/slideshow/SimpleSlideShow';
import SEO from '../components/SEO/SEO';

class BlogIndex extends React.Component {
  render() {
    const posts = _.get(this, 'props.data.allMarkdownRemark.edges');
    return (
      <Layout>
        <SEO />
        <div className="text-center">
          <h1 className="section-title">Welcome!</h1>
        </div>
        <div className="home d-flex flex-row flex-wrap">
          {posts.map(({ node }) => {
            const title = _.get(node, 'frontmatter.title') || node.fields.slug;
            let featuredImgFluid = _.get(node, 'frontmatter.featuredImage.childImageSharp.fluid');
            let postTitle;
            let imageQuery = "";
            if(featuredImgFluid) {
              postTitle = "";
              imageQuery = <Link to={node.fields.slug}><Img fluid={featuredImgFluid} /></Link>
            };
            return (
              <div className="col-md-4 col-sm-6 col-lg-3 col-xs-12 mb-4" key={node.fields.slug}>
                <div className="post shade-me h-100"> 
                  {postTitle}
                  {imageQuery}
                  <p className="subtitle">
                    <h3><Link class="title" to={node.fields.slug}>{title}</Link></h3>
                    <h6 dangerouslySetInnerHTML={{ __html: node.frontmatter.spoiler }} />
                    {/* {node.frontmatter.tags.map(tag => (
                        <span key={tag} className="subtitle-tag">
                          <Link to={'/tags/' + tag.toLowerCase()}>#{tag}</Link>
                        </span>
                    ))} */}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {frontmatter: {type: {in: "Bird"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            type
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
